import {breakpoints, gridGutter } from '@olmokit/core/scss';
import { $, $all, on } from '@olmokit/dom';
import { globalData } from '@olmokit/core/data';
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  AutoHeight,
  Swipe,
  Hover
} from '@olmokit/core/glide';
import '@olmokit/core/glide/bullets';
import './Projects.scss';
import 'components/Modal/Img';
import './index.scss';

/**
* Component: SliderGallery
*/
export default function sliderProjects(rootSelector) {
  const $container = `${rootSelector ? rootSelector + ' ' : ''}`;

  const glideOptions = {
    type: "carousel",
    rewind: true,
    autoplay: 4000,
    autoHeight: true,
    startAt: 1,
    perView: 8,
    breakpoints: {
        480: {
          perView: 2,
        },
        1000: {
          perView: 5,
        },
    },
  };

  const slider = $container;

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
    Anchors, 
    Autoplay, 
    Breakpoints, 
    Controls,
    AutoHeight,
    Swipe,
    Hover 
  });        


  return slider; 
}


