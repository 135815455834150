import { $, $all, on, addClass, removeClass } from '@olmokit/dom';
import "./Img.scss";

const closeButton = $('.close-button');
const modal = $('#image-modal');
const imgContainer = $('.modal_zoomimg');

$all('.img_project').forEach((img) => {
  on(img, 'click', () => {
    const image = new Image();
    image.src = img.getAttribute('src');
    image.alt = img.getAttribute('alt');
    image.width = parseInt(img.getAttribute('swidth'));
    image.height = parseInt(img.getAttribute('sheight'));
    imgContainer.innerHTML = '';
    imgContainer.appendChild(image);
    addClass(modal, 'view');
  })
})

closeButton.addEventListener('click', () => {
//   modal.style.display = 'none';
  removeClass(modal, 'view');
});

on(window, 'click', (event) => {
  if (event.target === modal) {
    // modal.style.display = 'none';
    removeClass(modal, 'view');
  }
});
