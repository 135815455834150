/**
 * @file Route: 503
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */
import "layouts/main";
// import "layouts/main/index.scss";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/typography/reset.scss";
import "./index.scss";

import { FormContact } from "components/Form/Contact";
import { replaceFragment } from "@olmokit/core/fragments";
import Masonry from 'masonry-layout';
import { $, $all } from '@olmokit/dom';
import sliderBig from "components/Slider/Big";
import sliderProjects from "components/Slider/Projects";
import sliderNews from "components/Slider/News";
import "components/Footer";

sliderBig('.SliderBig:');
sliderProjects('.SliderProjects:');
sliderNews('.SliderNews:');


console.log("Route segnalazioni/index.js mounted.");
replaceFragment("FormContact", "components.FormContact").then(() => FormContact());

document.addEventListener('DOMContentLoaded', function() {
    
    // console.log('load')
    const imgs = $all('.imgactivities');
    // console.log('imgs', imgs)
    const containers = $all('.Activities:mosaic_block');
    // console.log('containers', containers)

    var msnry = new Masonry( '.serv_grid', {
        columnWidth: '.serv_sizer',
        percentPosition: true,
        horizontalOrder: true,
      });


 }, false);