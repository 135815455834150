import "@olmokit/core/forms/checkbox";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import FormsTextareaMaterial from "@olmokit/core/forms/textarea/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import "./index.scss";


/**
 * Component: FormContact
 *
 */
export function FormContact() {

  const fillform = Olmoforms(".FormContact:", {
    sent: (formData) => {
      dataLayer.push({
        ...formData,
        event: "form sent",
        form: "Form Contatti",
      });
    },
  });

  Dependency(".FormContact:");
  FormsInputMaterial();
  FormsSelectMaterial();
  FormsTextareaMaterial();

  return {
    destroy() {
      fillform.destroy();
    },
  };
}