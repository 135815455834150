import {breakpoints, gridGutter } from '@olmokit/core/scss';
import { $, $all } from '@olmokit/dom';
import { globalData } from '@olmokit/core/data';
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  AutoHeight,
  Swipe,
  Hover
} from '@olmokit/core/glide';
import '@olmokit/core/glide/bullets';
// import '@olmokit/core/glide/arrows';
import './News.scss';

/**
* Component: SliderGallery
*/
export default function sliderNews(rootSelector) {
  const $container = `${rootSelector ? rootSelector + ' ' : ''}`;
  
  const glideOptions = {
    type: "carousel",
    hoverpause: true,
    // animationDuration: 900,
    // killWhen: { above: 99999 },
    // bound: true,
    // startAt: 1,
    // gap: 160,
    autoHeight: false, // if true, remove {align-items: center;} from .glide__slides
    perView: 3,
    breakpoints: {
      480: {
        perView: 1
      }
    }
  };

  const slider = $container;

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
    Anchors, 
    Autoplay, 
    Breakpoints, 
    Controls,
    AutoHeight,
    Swipe,
    Hover 
  });  

      
      

      return slider; 
}


