import "./index.scss";
import { $, on, addClass, removeClass, hasClass } from "@olmokit/dom";



on($('.Header:toggle'), 'click', () => {
    let header = $('.Header:Mobile');
    let body = $("body");
    
    if(header.classList.contains('open')){
        removeClass(header, 'open');
        body.style.overflow = "auto";
    }else{
        addClass(header, 'open');
        body.style.overflow = "hidden";
    }
})
on($('.Header:Mobile'), 'click', () => {
    $('.Header:toggle').click();
})