import {breakpoints, gridGutter } from '@olmokit/core/scss';
import { $, $all, removeClass, addClass } from '@olmokit/dom';
import { globalData } from '@olmokit/core/data';
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  AutoHeight,
  Swipe,
  Hover
} from '@olmokit/core/glide';
import '@olmokit/core/glide/bullets';
// import '@olmokit/core/glide/arrows';
import './Big.scss';

/**
* Component: SliderGallery
*/
var slide = 0;
let count = parseInt( $('#bigSlider').getAttribute('data-length') );

let changedSlide = (value) => {
    let old_index = slide;
    let old_bg = $(`[bg-index="${slide}"]`);
    
    if(value.direction == ">"){
      slide = slide + value.steps;
      if(slide == count){
        slide -= count;
      }
    }
    if(value.direction == "<"){
      slide = slide - value.steps;
      if(slide < 0){
        slide +=count;
      }
    }
    if(value.direction == "="){
      slide = value.steps;
    }
    if(slide != old_index){
      let new_bg = $(`[bg-index="${slide}"]`);
      addClass(new_bg, 'show')
      removeClass(old_bg, 'show')
    }
} 

export default function sliderBig(rootSelector) {
  let i = 0;
  const $container = `${rootSelector ? rootSelector + ' ' : ''}`;

  const glideOptions = {
    type: "carousel",
    rewind: true,
    // autoplay: 6000,
    // hoverpause: true,
    // animationDuration: 900,
    // killWhen: { above: 99999 },
    // bound: true,
    startAt: 0,
    gap: 0,
    //autoHeight: true, // if true, remove {align-items: center;} from .glide__slides
    perView: 1,
    peek: {
      before: 200,
      after: 200
    },
    breakpoints: {
      700: {
        peek: {
          before: 0,
          after: 0
        }
      },
      1000: {
        peek: {
          before: 0,
          after: 0
        }
      },
      1200: {
        peek: {
          before: 150,
          after: 150
        }
      }
    }
  };

  const slider = $container;

  const sliderImages = glide(slider, glideOptions);

  sliderImages.on('run.before', function(data) {

    changedSlide(data)
    // console.log($(`[data-glide-idx="${slide}"]`).getElementsByTagName('img')[0].src)
    
  })

  sliderImages.mount({
    Anchors, 
    Autoplay, 
    Breakpoints, 
    Controls,
    AutoHeight,
    Swipe,
    Hover 
  });  

}



